var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loadingPage)?_c('div',{staticClass:"pa-3"},[_c('div',{staticClass:"mb-3"},[_c('v-btn',{attrs:{"small":"","fab":"","depressed":"","color":"green","dark":""},on:{"click":_vm.getBack}},[_c('v-icon',[_vm._v("mdi-reply")])],1)],1),_c('div',{staticClass:"pa-3 d-flex align-center justify-center"},[_c('div',{staticClass:"_80w px-5"},[_vm._m(0),(_vm.form)?_c('div',{staticClass:"pa-3"},[_vm._l((_vm.form.data),function(item,idx){return _c('section',{key:idx},[(item.type == 'integer' && item.label !== 'Scholar')?_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(item.label)+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"type":"number","hide-details":"auto","label":item.placeholder,"error-messages":_vm.required[item.attribute] == true
                      ? 'Data tidak boleh kosong'
                      : '',"required":"","solo":"","dense":""},on:{"keyup":function($event){return _vm.isInt(_vm.model[item.attribute], item.attribute)}},model:{value:(_vm.model[item.attribute]),callback:function ($$v) {_vm.$set(_vm.model, item.attribute, $$v)},expression:"model[item.attribute]"}})],1)],1):_vm._e(),(item.type == 'text')?_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(item.label)+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":item.placeholder,"error-messages":_vm.required[item.attribute] == true
                      ? 'Data tidak boleh kosong'
                      : '',"required":"","solo":"","dense":""},model:{value:(_vm.model[item.attribute]),callback:function ($$v) {_vm.$set(_vm.model, item.attribute, $$v)},expression:"model[item.attribute]"}})],1)],1):_vm._e(),(item.type == 'textarea' && item.attribute !== 'deskripsi')?_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(item.label)+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-card',{staticClass:"pa-3",staticStyle:{"cursor":"pointer"},attrs:{"width":"100%","height":"150px"},nativeOn:{"click":function($event){return _vm.showTxt(item)}}},[(!_vm.model[item.attribute])?_c('p',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(item.placeholder)+" ")]):_vm._e(),(_vm.model[item.attribute])?_c('p',[_c('span',{directives:[{name:"snip",rawName:"v-snip",value:(5),expression:"5"}],domProps:{"innerHTML":_vm._s(_vm.model[item.attribute])}})]):_vm._e()])],1)],1):_vm._e(),(item.type == 'dropdown' && item.attribute !== 'periode')?_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(item.label)+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{attrs:{"items":item.value,"dense":"","hide-details":"auto","label":item.placeholder,"solo":"","error-messages":_vm.required[item.attribute] == true
                      ? 'Data tidak boleh kosong'
                      : ''},model:{value:(_vm.model[item.attribute]),callback:function ($$v) {_vm.$set(_vm.model, item.attribute, $$v)},expression:"model[item.attribute]"}})],1)],1):_vm._e(),(item.type == 'daterange')?_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(item.label)+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{ref:"menu",refInFor:true,attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":item.placeholder,"prepend-inner-icon":"mdi-calendar","readonly":"","hide-details":"","dense":"","solo":""},model:{value:(_vm.dateRangeTxt),callback:function ($$v) {_vm.dateRangeTxt=$$v},expression:"dateRangeTxt"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.calendar1),callback:function ($$v) {_vm.calendar1=$$v},expression:"calendar1"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.model[item.attribute]),callback:function ($$v) {_vm.$set(_vm.model, item.attribute, $$v)},expression:"model[item.attribute]"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.calendar1 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.saveDate(_vm.model[item.attribute])}}},[_vm._v(" OK ")])],1)],1)],1)],1):_vm._e()],1)}),_c('footer',{staticClass:"d-flex justify-end mt-3"},[(!_vm.loading)?_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"green","dark":""},on:{"click":_vm.validateRequire}},[_vm._v("Save")]):_vm._e(),(_vm.loading)?_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"green","dark":"","depressed":""}},[_c('v-progress-circular',{staticClass:"mr-2",attrs:{"size":25,"indeterminate":""}}),_vm._v(" Loading")],1):_vm._e()],1),_c('div',{staticClass:"pa-3"},[_c('TextareaInput',{ref:"txtEditor",attrs:{"dialogTextarea":_vm.dialogTextarea,"dataTxt":_vm.dataTxt,"typed":_vm.typed},on:{"close":function($event){return _vm.closeDialog(1)},"save":_vm.mutateTxt}})],1)],2):_vm._e()])])]):_vm._e(),(_vm.loadingPage)?_c('div',[_c('Loading')],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('h1',{staticClass:"text-center"},[_vm._v("Create Report")])])}]

export { render, staticRenderFns }