<template>
  <div>
    <div class="pa-3" v-if="!loadingPage">
      <div class="mb-3">
        <v-btn small fab depressed color="green" dark @click="getBack">
          <v-icon>mdi-reply</v-icon>
        </v-btn>
      </div>
      <div class="pa-3 d-flex align-center justify-center">
        <div class="_80w px-5">
          <header>
            <h1 class="text-center">Create Report</h1>
          </header>
          <div v-if="form" class="pa-3">
            <section v-for="(item, idx) in form.data" :key="idx">
              <v-row v-if="item.type == 'integer' && item.label !== 'Scholar'">
                <v-col cols="4"> {{ item.label }} </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="model[item.attribute]"
                    type="number"
                    hide-details="auto"
                    :label="item.placeholder"
                    :error-messages="
                      required[item.attribute] == true
                        ? 'Data tidak boleh kosong'
                        : ''
                    "
                    required
                    @keyup="isInt(model[item.attribute], item.attribute)"
                    solo
                    dense
                  ></v-text-field
                ></v-col>
              </v-row>
              <v-row v-if="item.type == 'text'">
                <v-col cols="4"> {{ item.label }} </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="model[item.attribute]"
                    hide-details="auto"
                    :label="item.placeholder"
                    :error-messages="
                      required[item.attribute] == true
                        ? 'Data tidak boleh kosong'
                        : ''
                    "
                    required
                    solo
                    dense
                  ></v-text-field
                ></v-col>
              </v-row>

              <v-row
                v-if="item.type == 'textarea' && item.attribute !== 'deskripsi'"
              >
                <v-col cols="4"> {{ item.label }} </v-col>
                <v-col cols="8">
                  <v-card
                    width="100%"
                    height="150px"
                    class="pa-3"
                    style="cursor: pointer"
                    @click.native="showTxt(item)"
                  >
                    <p class="grey--text" v-if="!model[item.attribute]">
                      {{ item.placeholder }}
                    </p>
                    <p v-if="model[item.attribute]">
                      <span v-snip="5" v-html="model[item.attribute]"></span>
                    </p>
                  </v-card>
                  <!-- <v-textarea
                    v-model="model[item.attribute]"
                    hide-details="auto"
                    :error-messages="
                      item.validator.max
                        ? maxErr
                        : required[item.attribute] == true
                        ? 'Data tidak boleh kosong'
                        : ''
                    "
                    :counter="item.validator.max ? true : false"
                    :label="item.placeholder"
                    solo
                  ></v-textarea> -->
                </v-col>
              </v-row>
              <v-row
                v-if="item.type == 'dropdown' && item.attribute !== 'periode'"
              >
                <v-col cols="4">
                  {{ item.label }}
                </v-col>
                <v-col cols="8">
                  <v-select
                    :items="item.value"
                    dense
                    v-model="model[item.attribute]"
                    hide-details="auto"
                    :label="item.placeholder"
                    solo
                    :error-messages="
                      required[item.attribute] == true
                        ? 'Data tidak boleh kosong'
                        : ''
                    "
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="item.type == 'daterange'">
                <v-col cols="4"> {{ item.label }} </v-col>
                <v-col cols="8">
                  <v-menu
                    ref="menu"
                    v-model="calendar1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateRangeTxt"
                        :label="item.placeholder"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        dense
                        solo
                      ></v-text-field>
                    </template>
                    <v-date-picker range v-model="model[item.attribute]">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="calendar1 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="saveDate(model[item.attribute])"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu></v-col
                >
              </v-row>
            </section>

            <footer class="d-flex justify-end mt-3">
              <v-btn
                @click="validateRequire"
                v-if="!loading"
                color="green"
                dark
                class="text-capitalize"
                >Save</v-btn
              >
              <v-btn
                v-if="loading"
                color="green"
                class="text-capitalize"
                dark
                depressed
                ><v-progress-circular
                  :size="25"
                  class="mr-2"
                  indeterminate
                ></v-progress-circular>
                Loading</v-btn
              >
            </footer>
            <div class="pa-3">
              <TextareaInput
                v-bind:dialogTextarea="dialogTextarea"
                v-bind:dataTxt="dataTxt"
                v-bind:typed="typed"
                @close="closeDialog(1)"
                @save="mutateTxt"
                ref="txtEditor"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loadingPage">
      <Loading />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import Loading from "../../../components/etc/loading-component/loadingLG.vue";
import TextareaInput from "../../../components/Report/Create/Modal/textareaInput.vue";

export default {
  name: "createReport",
  components: { Loading, TextareaInput },

  computed: {
    ...mapState({
      form: (state) => state.report.form_report,
    }),
  },
  data() {
    return {
      model: {},
      validateInt: {},
      required: {},
      loading: false,
      loadingPage: false,
      calendar1: false,
      dateRangeTxt: "",
      maxErr: "",
      date_range: [],
      dialogTextarea: false,
      dataTxt: null,
      typed: null,
    };
  },
  mounted() {
    this.getForm();
  },
  methods: {
    getBack() {
      this.$router.go(-1);
    },
    saveDate(time) {
      this.$refs.menu[0].save(time);
      let isYearSame =
        this.$date(time[0]).format("YYYY") ==
        this.$date(time[1]).format("YYYY");
      let isMontSame =
        this.$date(time[0]).format("MM") == this.$date(time[1]).format("MM");
      if (isYearSame) {
        if (isMontSame) {
          this.dateRangeTxt = `${this.$date(time[1]).format("MMMM YYYY")}`;
        } else {
          this.dateRangeTxt = `${this.$date(time[0]).format(
            "MMMM"
          )} - ${this.$date(time[1]).format("MMMM YYYY")}`;
        }
      } else {
        this.dateRangeTxt = `${this.$date(time[0]).format(
          "MMMM YYYY"
        )} - ${this.$date(time[1]).format("MMMM YYYY")}`;
      }
    },
    isInt(val, attr) {
      if (val !== undefined) {
        if (val)
          if (Number.isInteger(parseInt(val))) {
            this.model[attr] = parseInt(this.model[attr]);
            this.validateInt[attr] = false;
          } else {
            console.log("Data yang di input harus berupa angka");
            this.validateInt[attr] = true;
            this.model[attr] = "";
            this.model = Object.assign({}, this.model, this.model);
          }
      }
    },
    getForm() {
      this.loadingPage = true;
      this.$store
        .dispatch("report/getForm", this.$route.params.periode)
        .then(() => {
          setTimeout(() => (this.loadingPage = false), 1000);
          let form = this.form.data;
          for (const key in form) {
            if (key !== "range_periode") {
              this.model[key] = null;
            }
          }
          this.getRequiredField();
        });
    },
    getRequiredField() {
      let form = this.form.data;
      for (const key in form) {
        if (form[key].validator == "required") {
          this.required[form[key].attribute] = false;
        }
      }
      this.required = Object.assign({}, this.required, this.required); //depth reactive bro, read documentation heula
    },
    validateRequire() {
      delete this.model["deskripsi"];
      let counter = 0;
      this.model["scholar_id"] = this.$route.params.id;
      this.model["periode"] = this.$route.params.periode;

      for (const key in this.required) {
        if (this.model[key] === null) {
          counter++;
          this.$set(this.required, key, true); //depth reactive bro, read documentation heula
        }
      }
      if (counter == 0) {
        this.createReport();
      }
    },
    createReport() {
      if (this.model["psychological_desc"].length > 1470) {
        this.maxErr = "This field has a maximum length of 1470 character!";
      } else {
        this.maxErr = "";
        this.loading = true;

        this.$store
          .dispatch("report/createReport", this.model)
          .then((data) => {
            this.loading = false;
            let form = this.form.data;
            for (const key in form) {
              this.model[key] = "";
            }
            this.$router.push(
              `/report/${this.$route.params.periode}/${this.$route.params.id}`
            );
            Swal.fire({
              icon: "success",
              title: data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000,
            });
          })
          .catch((err) => {
            this.loading = false;
            Swal.fire({
              icon: "success",
              title: err.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000,
            });
          });
      }
    },
    mutateTxt(data) {
      this.model[data.attr] = data.txt;
      this.closeDialog(1);
    },
    showTxt(item) {
      this.dataTxt = item;
      this.dialogTextarea = true;
      this.typed = this.model[item.attribute];
      this.$refs.txtEditor.mutableTxt(this.typed);
    },
    closeDialog(q) {
      switch (q) {
        case 1:
          this.dialogTextarea = false;
          this.dataTxt = null;
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style scopde>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
